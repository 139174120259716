<template>
  <v-container>
    <backArrow style="margin-left: 12px; margin-top: 5px; position: initial;" :action="() => onClickBackArrow()"/>

    <v-row class="px-3 py-3">
      <v-col cols="12">
        <p class="main-title mb-0 pb-0">Telemedicina</p>
        <p class="mt-2" style="color: var(--v-primary)">Confirme os dados cadastrais</p>
      </v-col>

      <v-col cols="12" class="pt-0">
        <v-card rounded="lg">
          <v-row>
            <v-col cols="9">
              <p style="color: var(--v-primary)" class="pl-4 mb-0 font-weight-bold">
                Dependente {{ indexOfDependent >=0 ? formatDigit(indexOfDependent) : '' }}
              </p>
              <p class="pl-4 mb-0 main-text text-truncate">
                {{ addDependent.name }}
              </p>
            </v-col>
            <v-col cols="3">
              <v-switch class="py-0 my-0" v-model="addDependent.isActive" color="primary" readonly></v-switch>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col col="12" class="pt-0 mt-0 mx-2">
        <v-sheet rounded="lg" style="background-color: rgba(255, 255, 255, .5) !important;">
          <v-form ref="formDependent" lazy-validation>
            <v-col cols="12">
              <v-text-field
                  placeholder="Data de nascimento"
                  label="Data de nascimento"
                  readonly
                  :value="formatDateInput"
              />
              <v-text-field
                  v-model="addDependent.documentNumber"
                  class="mb-2"
                  v-mask="['###.###.###-##']"
                  label="CPF"
                  placeholder="CPF"
                  :rules="[rules.requiredCPF]"
              />
              <v-text-field
                  v-model="addDependent.kinship"
                  class="mb-2"
                  label="Parentesco"
                  placeholder="Parentesco"
                  readonly
              />
              <v-text-field
                  v-model="phone"
                  class="mb-2"
                  type="tel"
                  label="Telefone"
                  placeholder="Telefone"
                  :rules="[rules.requiredPhone]"
                  v-mask="['(##) ####-####', '(##) #####-####']"
              />
              <v-text-field
                  label="E-mail"
                  type="email"
                  v-model="addDependent.email"
                  :rules="[rules.email, rules.requiredText]">
              </v-text-field>
              <google-map-autocomplete
                  v-if="!mapsCep || editAddress"
                  cols="12"
                  class="mb-6 pb-6"
                  @input="upateAddress"
                  :loadSearch="googleSearch"
                  placeholder="Digite o endereço completo"
              />
            </v-col>
          </v-form>
        </v-sheet>
      </v-col>

      <v-col cols="12" class="px-4">
        <generic-btn
            style="margin-top: 2%; width: 100%"
            :btn-props="{ disabled: disableSave }"
            :on-click="() => saveDependent()"
        >
          Salvar
        </generic-btn>
      </v-col>
    </v-row>

    <v-card elevation="16" v-if="addLocal" class="medical-speciality">
      <v-container class="pa-10 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="12">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Local
            </p>
          </v-col>
        </v-row>

        <v-text-field
          label="CEP"
          v-model="local.cep"
          :rules="[rules.validCEP]"
          v-mask="'#####-###'"
        />

        <v-text-field label="Rua" v-model="local.address" />
        <v-text-field
          label="Nº"
          :rules="[rules.limitNumber]"
          v-model="local.number"
        />
        <v-text-field label="Bairro" v-model="local.neighbour" />
        <v-text-field label="Cidade" v-model="local.city" />
        <v-text-field label="Estado" v-model="local.state" disabled />
        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{ disabled: disableLocalForm }"
          :on-click="saveAddress"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { regexValidations } from "../../common/constants";
import { mapMutations } from "vuex";
import { regexEmail } from "../../common/constants";
import backArrow from '../../components/covid/back-arrow';
import moment from "moment";
import GoogleMapAutocomplete from "../../components/google-maps/google-map-autocomplete";
import ContractService from "../../services/contract/ContractService";
import AddressService from "@/services/contract/AddressService";

export default {
  name: "InsertDependentTelemedicine",
  components: {
    GoogleMapAutocomplete,
    backArrow
  },
  data() {
    return {
      moment,
      editAddress: false,
      addDate: false,
      addLocal: false,
      phone: "",
      indexOfDependent: -1,
      dependents: [],
      addDependent: {
        name: "",
        birth: null,
        phone: {
          number: "",
          areaCode: "",
        },
        kinship: null,
        email: null,
        isActive: true,
        documentNumber: null,
        holderDocumentNumber: null,
      },
      local: {
        cep: null,
        neighbour: "",
        address: "",
        number: "",
        city: "",
        state: "",
        country: "",
      },
      mapsCep: null,
      googleSearch: '',
      rules: {
        limitNumber: (value) => value && value.length <= 10 || 'Máximo 10 caracteres',
        validCEP: (value) => value && value.length === 9 || 'CEP inválido',
        requiredPhone: (value) => value && value.length >= 14 || 'Campo obrigatório',
        requiredText: (value) => !!value || 'Campo obrigatório',
        requiredCPF: (value) => value && value.length === 14 || 'Campo obrigatório',
        email: (value) => {
          if (value && value.length > 0) {
            const pattern = regexEmail;
            return pattern.test(value) || 'E-mail inválido';
          }
          return true;
        }
      },
    };
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    onClickBackArrow() {
      this.$router.push({ name: 'telemedicineDependentsManagement', params: { dependents: this.dependents, isSaved: true } });
    },
    upateAddress(data) {
      const { code, neighborhood, region, city, log, number, country } = data;

      this.local.cep = code;
      this.local.neighbour = neighborhood;
      this.local.address = log;
      this.local.city = city;
      this.local.state = region;
      this.local.number = number;
      this.local.country = country;

      // Enable Form to finish address register
      this.addLocal = true;
    },
    loadGoogleSearchField(data) {
      if(data) {
        if(data.log) {
          this.googleSearch = this.googleSearch + ' ' + data.log
        }
        if(data.number) {
          this.googleSearch = this.googleSearch + ', ' + data.number
        }
        if(data.neighborhood) {
          this.googleSearch = this.googleSearch + ' - ' + data.neighborhood
        }
        if(data.city) {
          this.googleSearch = this.googleSearch + ', ' + data.city
        }
        if(data.region) {
          this.googleSearch = this.googleSearch + ' - ' + data.region
        }
        if(data.country) {
          this.googleSearch = this.googleSearch + ', ' + data.country
        }
      }
    },
    saveAddress() {
      this.addLocal = false;
      this.editAddress = false;
    },
    saveDependent() {
      if(!this.$refs.formDependent.validate()) {
        this.showmsg({text: "Por favor, preencha os campos corretamente.", type: "error" })
        return;
      }

      this.loading(true);

      this.formatPhone(this.phone);
      this.dependents[this.indexOfDependent].isActive = true;
      this.dependents[this.indexOfDependent].isSaved = true;
      this.dependents[this.indexOfDependent].physicalPerson.cellphoneDDD = this.addDependent.phone.areaCode;
      this.dependents[this.indexOfDependent].physicalPerson.cellphoneNumber = this.addDependent.phone.number;
      this.dependents[this.indexOfDependent].physicalPerson.email = this.addDependent.email;
      this.dependents[this.indexOfDependent].physicalPerson.cpf = this.$util.removerPontuacaoCPF(this.addDependent.documentNumber);
      this.dependents[this.indexOfDependent].physicalPerson.address = {
        active: true,
        addressZip: this.local.cep.replace(/[^\d]+/g, ""),
        address: this.local.address,
        addressNeighborhood: this.local.neighbour,
        addressNumber: this.local.number,
        addressCity: this.local.city,
        addressCountry: this.local.country,
        addressState: this.local.state
      }

      this.loading(false);
      this.$router.push({ name: 'telemedicineDependentsManagement', params: { dependents: this.dependents, isSaved: true } });
    },
    formatDigit(index) {
      if(index >= 0 && index < 10) {
        index++;
        return ("0" + index).slice(-2);
      }
      return index;
    },
    formatPhone(phone) {
      if(phone) {
        const formattedPhone = phone.split(" ");
        this.addDependent.phone.areaCode = formattedPhone[0].replace(/[^\d]+/g, "");
        this.addDependent.phone.number = formattedPhone[1].replace(/[^\d]+/g, "");
      }
    },
    clearDependent() {
      this.addDependent = {
        name: "",
        birth: null,
        phone: {
          number: "",
          areaCode: "",
        },
        documentNumber: null,
        holderDocumentNumber: null,
      };
      this.local = {
        cep: null,
        neighbour: "",
        address: "",
        number: "",
        city: "",
        state: "",
        country: "",
      };
      this.mapsCep = null;
      this.phone = "";
    },
    async loadActiveAddressByCpf(cpf) {
      if(cpf) {
        await this.addressService.FindActiveAddressByCpf(cpf)
            .then(result => {
              if(result && result.data) {
                const data = {
                  code: result.data.addressZip,
                  log: result.data.address,
                  neighborhood: result.data.addressNeighborhood,
                  number: result.data.addressNumber,
                  city: result.data.addressCity,
                  country: result.data.addressCountry,
                  region: result.data.addressState
                }
                this.upateAddress(data);
                this.loadGoogleSearchField(data);
              }
            })
            .catch(() => {
              this.loading(false);
            })
      }
    },
    loadAddressEdited(address) {
      if(address) {
        const data = {
          code: address.addressZip,
          log: address.address,
          neighborhood: address.addressNeighborhood,
          number: address.addressNumber,
          city: address.addressCity,
          country: address.addressCountry,
          region: address.addressState
        }
        this.upateAddress(data);
        this.loadGoogleSearchField(data);
      }
    }
  },
  computed: {
    disableLocalForm() {
      return (
        !this.local.address ||
        !this.local.number ||
        (this.local.cep && !this.local.cep.match(regexValidations.cep))
      );
    },
    formatDateInput() {
      return this.addDependent.birth
        ? moment(this.addDependent.birth).format("DD/MM/YYYY")
        : "";
    },
    disableSave() {
      return (!this.addDependent.birth || !this.phone || !this.local.cep || !this.addDependent.documentNumber);
    },
  },
  async mounted() {
    this.addressService = new AddressService();
    this._contractService = new ContractService();

    if(this.$route.params && this.$route.params.dependent && this.$route.params.dependents && this.$route.params.indexOfDependent >= 0) {
      this.loading(true);
      if(this.$route.params.dependent && !this.$route.params.dependent.isActive) {
        await this.loadActiveAddressByCpf(this.$route.params.dependent.physicalPerson.cpf);
      } else {
        if(this.$route.params.dependent.physicalPerson && this.$route.params.dependent.physicalPerson.address) {
          this.loadAddressEdited(this.$route.params.dependent.physicalPerson.address);
        }
      }

      this.loading(false);

      this.dependents = this.$route.params.dependents;
      this.indexOfDependent = this.$route.params.indexOfDependent;

      this.addDependent.kinship = this.$route.params.dependent && this.$route.params.dependent.kinship ? this.$route.params.dependent.kinship.description : null;
      this.addDependent.name = this.$route.params.dependent.physicalPerson ? this.$route.params.dependent.physicalPerson.name : null;
      this.addDependent.email =  this.$route.params.dependent.physicalPerson ? this.$route.params.dependent.physicalPerson.email : null;
      this.addDependent.birth = this.$route.params.dependent.physicalPerson ? this.$route.params.dependent.physicalPerson.birthDate : null;
      this.addDependent.documentNumber = this.$route.params.dependent.physicalPerson ? this.$util.formatCPF(this.$route.params.dependent.physicalPerson.cpf) : null;
      this.phone = this.$route.params.dependent.physicalPerson && this.$route.params.dependent.physicalPerson.cellphoneDDD  && this.$route.params.dependent.physicalPerson.cellphoneNumber
          ? this.$util.removerPontuacaoCPF(this.$route.params.dependent.physicalPerson.cellphoneDDD) + this.$util.removerPontuacaoCPF(this.$route.params.dependent.physicalPerson.cellphoneNumber) : null;

      this.addDependent.holderDocumentNumber = this.$route.params.dependent.beneficiary && this.$route.params.dependent.beneficiary.physicalPerson
          ? this.$route.params.dependent.beneficiary.physicalPerson.cpf : null;

    } else {
      this.$router.push({name: 'minha-empresa'});
    }

    this.$refs.formDependent.resetValidation();

    if (window) {
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style>
.medical-speciality {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  width: 100vw;
  height: 90%;
  position: fixed;
  top: 15%;
  left: 0;
  bottom: 0;
  z-index: 20;
  -webkit-animation: medical-speciality 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: medical-speciality 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
</style>
